.others-card-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 330px;
  width: 330px;
}

.othersCard {
  height: 300px;
  width: 300px;
  background-color: #112240;
  padding: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
}

.othersCard:hover {
  transition: transform .5s;
  transform: scale(1.01);
  box-shadow: 0 8px 8px 0 rgba(13, 153, 0, 0.644), 0 6px 20px 0 rgba(9, 163, 4, 0.15);
}

.CiFolderOn {
  height: 36px;
  width: 36px;
}

.othersCard h3 {
  font-size: 14px;
  font-weight: bold;
}

.othersCard p {
  font-size: 16px;
  color: #A1ABCA;
}

.tecs {
  display: flex;
  justify-content: center;
}

.tecs p {
  padding: 10px;
}



@media (max-width: 480px) {
  .othersCard {
    width: 360px;
  }
}