.footer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.footer p {
  color: #fff;
  font-size: 14px;
}

.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.github, .linkedin {
  color: #fff;
  height: 24px;
  width: 24px;
  padding: 0 5px 0 5px;
}

@media (max-width: 480px) {
  .footer {
    height: 120px;
    width: 100%;
  }

  .footer-container {
    display: flex;
    flex-direction: column;
  }

  .github, .linkedin {
    color: #fff;
    height: 32px;
    width: 32px;
  }
}