.container {
  width: 960px;
  margin: 0 auto;
}

.othersCard-container {
  display: grid;
  grid-template-columns: 320px 320px 320px;
  margin-bottom: 40px;
  justify-content: center;
}

@media (max-width: 480px) {
  .container {
    width: 360px;
  }

  .othersCard-container {
    grid-template-columns: 300px;
  }
}