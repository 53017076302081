.section-container {
  width: 1160px;
  margin: 0 auto 100px;
}

.portfolio-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  .section-container {
    width: 360px;
  }

  .portfolio-container {
    justify-content: center;
  }
}