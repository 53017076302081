.title {
  display: flex;
  align-items: center;
}

.title h3 {
  font-weight: bold;
  font-size: 36px;
}

.line {
  border: 0.5px solid;
  height: 0;
  width: 320px;
  margin-left: 100px;
}

@media (max-width: 360px) {
  .title {
    justify-content: center;
  }

  .title h3 {
    font-size: 24px;
  }

  .line {
    margin-left: 20px;
    width: 180px;
  }
}