.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 960px;
  margin: 0 auto;
}

.logo img {
  height: 76px;
  width: 76px;
}

.header-menu li {
  display: inline;
  margin-left: 20px;
  font-weight: bold;
  color: #64FFDA;
}

@media (max-width: 480px) {
  .header {
    width: 360px;
  }

  .header-menu li {
    display: none;
  }
}