.about {
  margin: 0 auto;
  width: 960px;
  display: flex;
  margin-bottom: 150px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

}

.about-text {
  display: inline-block;
  width: 50%;
  margin-top: 40px;
}

.about-text p {
  color: #8892B0;
}

.about-text li {
  list-style: disc;
  list-style-position: inside;
  color: #54D6BB;
  margin: 2px;
}

.profile-container {
  margin-left: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styled-border {
  display: inline-block;
  height: 200px;
  width: 200px;
  border-color: #54D6BB;
  border-style: solid;
  border-radius: 16px;
  border-width: 1px;
}

.profile-img {
  display: inline-block;
  bottom: 10px;
  right: 10px;
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 16px;
}

@media (max-width: 480px) {
  .about {
    width: 300px;
  }

  .about-text {
    width: 300px;
  }

  .styled-border {
    margin: 60px auto;
  }
}