.card {
  /* display: inline-block; */
  height: 520px;
  width: 360px;
  border: 2px solid #54D6BB;
  border-radius: 16px;
}

.card:hover {
  transition: transform .5s;
  transform: scale(1.01);
  box-shadow: 0 8px 17px 0 rgba(13, 153, 0, 0.644), 0 6px 20px 0 rgba(9, 163, 4, 0.15);
}

.project-description h3, .project-info p {
  text-align: center;
}

.project-info {
  margin: 0 auto;
  width: 320px;
}

.container-img {
  height: 200px;
  width: 320px;
  margin: 20px auto;
  border-radius: 10px;
  overflow: hidden;
}

.container-img img {
  max-width: 100%;
}

.portfolio-icons {
  display: flex;
  justify-content: center;
}

.portfolio-icons a {
  text-decoration: none;
  color: #fff
}

.portfolio-github, .portfolio-external-link {
  height: 40px;
  width: 40px;
  padding: 10px;
}

.tecnology {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.tecnology p {
  color: #54D6BB;
  padding: 10px;
}

@media (max-width: 480px) {
  .card {
    margin-top: 20px;
    height: 520px;
    width: 328px;
    border: 2px solid #54D6BB;
    border-radius: 16px;
  }

  .container-img {
    margin-top: 20px;
    height: 180px;
    width: 288px;
  }

  .card h3 {
    font-size: 16px;
    text-align: center;
  }

  .project-description {
    flex-direction: column;
  }

  .project-info {
    text-align: center;
    margin-left: 0;
  }

  .portfolio-icons {
    display: flex;
    justify-content: center;
  }

  .external-link {
    margin: 0;
  }


  .portfolio-github, .portfolio-external-link {
    padding: 20px;
    height: 40px;
    width: 40px;
  }
}