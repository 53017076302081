.hero-page {
  margin: 0 auto;
  box-sizing: border-box;
  height: 90vh;
  width: 960px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.name-title, .job-title {
  margin-bottom: 20px;
}

.job-title {
  color: #8892B0;
}

.name-title {
  font-size: 64px;
  font-weight: bold;
}

.social-media a:nth-child(2) {
  margin-left: 20px;
}

@media (max-width: 480px) {
  .hero-page {
    margin-left: 24px;
    height: 40vh;
    width: 360px;
  }

  .name-title {
    font-size: 24px;
    font-weight: bold;
  }
}